// React
import React from 'react';

// Blockly
import 'blockly/blocks';

// VMT Blockly
import { Category, Block, Value, Shadow, Field, Next, Sep, Label, Statement } from 'components/BlocklyComponent';

export const ToolBox = (
    <>
        <Category name="Bricks" colour="210"></Category>
        <Category name="Variables" categorystyle="variable_category" custom="VMT_VARIABLE"></Category>
        <Sep></Sep>
        {/* <Category name="Advanced" expanded="true" > */}
        <Category name="Actions" colour="310">
            <Label text="Actions" web-class="ioLabel"></Label>
            <Block type="action_text">
                <Value name="TEXT">
                    <Shadow type="text">
                        <Field name="TEXT">text</Field>
                    </Shadow>
                </Value>
            </Block>
            <Block type="action_text_stack">
                <Value name="TEXT">
                    <Shadow type="text">
                        <Field name="TEXT">text</Field>
                    </Shadow>
                </Value>
            </Block>
            <Block type="action_type"></Block>
            <Block type="action_stack"></Block>
            <Block type="action_constrained_stack">
                <Value name="CONSTRAINT">
                    <Shadow type="math_number">
                        <Field name="NUM">0</Field>
                    </Shadow>
                </Value>
                <Next>
                    <Block type="action_stack"></Block>
                </Next>
            </Block>
            <Block type="action_constrained_stack">
                <Value name="CONSTRAINT">
                    <Shadow type="math_number">
                        <Field name="NUM">0</Field>
                    </Shadow>
                </Value>
                <Next>
                    <Block type="action_text_stack">
                        <Value name="TEXT">
                            <Shadow type="text">
                                <Field name="TEXT">text</Field>
                            </Shadow>
                        </Value>
                    </Block>
                </Next>
            </Block>
            <Label text="Getters" web-class="ioLabel"></Label>
            <Block type="attr_getter"></Block>
            <Block type="attr_getter_stack"></Block>
            <Label text="Grouping" web-class="ioLabel"></Label>
            <Block type="vmt_group"></Block>
            {/* <Block type="action_value_to_statement" disabled="true"></Block> */}
        </Category>
        <Category name="Validators" colour="310">
            <Label text="Expect Actions" web-class="ioLabel"></Label>
            <Block type="expect_action"></Block>
            <Block type="expect_action_stack"></Block>
            {/* <Block type="is_validator">
                <Value name="EXPECTED_VALUE">
                    <Shadow type="logic_boolean">
                        <Field name="BOOL">TRUE</Field>
                    </Shadow>
                </Value>
            </Block>
            <Block type="is_validator_stack">
                <Value name="EXPECTED_VALUE">
                    <Shadow type="logic_boolean">
                        <Field name="BOOL">TRUE</Field>
                    </Shadow>
                </Value>
            </Block> */}
            <Label text="Evaluate Bricks" web-class="ioLabel"></Label>
            <Block type="evaluate_brick">
                <Value name="EXPECTED_VALUE">
                    <Shadow type="text">
                        <Field name="TEXT">text</Field>
                    </Shadow>
                </Value>
            </Block>
            <Block type="vmt_group">
                <Statement name="GROUP_STATEMENT">
                    <Block type="evaluate_brick">
                        <Value name="EXPECTED_VALUE">
                            <Shadow type="text">
                                <Field name="TEXT">text</Field>
                            </Shadow>
                        </Value>
                    </Block>
                </Statement>
            </Block>
        </Category>
        <Category name="Browser Actions" colour="330">
            <Block type="browser_commands">
                <Value name="VALUE">
                    <Shadow type="text">
                        <Field name="TEXT">https://</Field>
                    </Shadow>
                </Value>
            </Block>
        </Category>
        <Sep></Sep>
        <Category name="Math" categorystyle="math_category">
            <Block type="math_number" gap="32"></Block>
            <Block type="math_arithmetic">
                <Value name="A">
                    <Shadow type="math_number">
                        <Field name="NUM">1</Field>
                    </Shadow>
                </Value>
                <Value name="B">
                    <Shadow type="math_number">
                        <Field name="NUM">1</Field>
                    </Shadow>
                </Value>
            </Block>
            <Block type="math_single">
                <Value name="NUM">
                    <Shadow type="math_number">
                        <Field name="NUM">9</Field>
                    </Shadow>
                </Value>
            </Block>
            <Block type="math_number_property">
                <Value name="NUMBER_TO_CHECK">
                    <Shadow type="math_number">
                        <Field name="NUM">0</Field>
                    </Shadow>
                </Value>
            </Block>
            <Block type="math_round">
                <Value name="NUM">
                    <Shadow type="math_number">
                        <Field name="NUM">3.1</Field>
                    </Shadow>
                </Value>
            </Block>
            <Block type="math_random_int">
                <Value name="FROM">
                    <Shadow type="math_number">
                        <Field name="NUM">1</Field>
                    </Shadow>
                </Value>
                <Value name="TO">
                    <Shadow type="math_number">
                        <Field name="NUM">100</Field>
                    </Shadow>
                </Value>
            </Block>
            <Block type="math_random_float"></Block>
        </Category>
        <Category name="Text" categorystyle="text_category">
            <Block type="text"></Block>
            {/* <Block type="text_join"></Block> */}
            <Block type="text_concat">
                <Value name="TEXT_A">
                    <Shadow type="text"></Shadow>
                </Value>
                <Value name="TEXT_B">
                    <Shadow type="text"></Shadow>
                </Value>
            </Block>
            <Block type="text_multiline"></Block>
            <Block type="text_isEmpty">
                <Value name="VALUE">
                    <Shadow type="text">
                        <Field name="TEXT"></Field>
                    </Shadow>
                </Value>
            </Block>
            <Block type="text_append">
                <Value name="TEXT">
                    <Shadow type="text"></Shadow>
                </Value>
            </Block>
            <Block type="text_length">
                <Value name="VALUE">
                    <Shadow type="text">
                        <Field name="TEXT">abc</Field>
                    </Shadow>
                </Value>
            </Block>
            <Block type="text_indexOf">
                <Value name="VALUE">
                    <Block type="vmt_variables_get">
                        <Field name="VAR">text</Field>
                    </Block>
                </Value>
                <Value name="FIND">
                    <Shadow type="text">
                        <Field name="TEXT">abc</Field>
                    </Shadow>
                </Value>
            </Block>
            <Block type="text_charAt">
                <Value name="VALUE">
                    <Block type="vmt_variables_get">
                        <Field name="VAR">text</Field>
                    </Block>
                </Value>
            </Block>
            <Block type="text_getSubstring">
                <Value name="STRING">
                    <Block type="vmt_variables_get">
                        <Field name="VAR">text</Field>
                    </Block>
                </Value>
            </Block>
            <Block type="text_changeCase">
                <Value name="TEXT">
                    <Shadow type="text">
                        <Field name="TEXT">abc</Field>
                    </Shadow>
                </Value>
            </Block>
            <Block type="text_trim">
                <Value name="TEXT">
                    <Shadow type="text">
                        <Field name="TEXT">abc</Field>
                    </Shadow>
                </Value>
            </Block>
            <Block type="text_count">
                <Value name="SUB">
                    <Shadow type="text"></Shadow>
                </Value>
                <Value name="TEXT">
                    <Shadow type="text"></Shadow>
                </Value>
            </Block>
            <Block type="text_replace">
                <Value name="FROM">
                    <Shadow type="text"></Shadow>
                </Value>
                <Value name="TO">
                    <Shadow type="text"></Shadow>
                </Value>
                <Value name="TEXT">
                    <Shadow type="text"></Shadow>
                </Value>
            </Block>
            <Block type="text_reverse">
                <Value name="TEXT">
                    <Shadow type="text"></Shadow>
                </Value>
            </Block>
            <Label text="Input/Output:" web-class="ioLabel"></Label>
            <Block type="text_print">
                <Value name="TEXT">
                    <Shadow type="text">
                        <Field name="TEXT">abc</Field>
                    </Shadow>
                </Value>
            </Block>
            <Block type="text_prompt_ext">
                <Value name="TEXT">
                    <Shadow type="text">
                        <Field name="TEXT">abc</Field>
                    </Shadow>
                </Value>
            </Block>
        </Category>
        <Category name="Logic" categorystyle="logic_category">
            <Block type="logic_boolean"></Block>
            <Block type="if_statement"></Block>
            <Block type="controls_if">
                <Value name="IF0">
                    <Block type="logic_compare">
                        {/* <Value name="A">
                                <Block type="vmt_variables_get"></Block>
                            </Value> */}
                        <Value name="B">
                            <Shadow type="text">
                                <Field name="TEXT"></Field>
                            </Shadow>
                        </Value>
                    </Block>
                </Value>
            </Block>
            <Block type="controls_if">
                <Value name="IF0">
                    <Block type="logic_compare">
                        <Value name="B">
                            <Shadow type="math_number">
                                <Field name="NUM">0</Field>
                            </Shadow>
                        </Value>
                    </Block>
                </Value>
            </Block>
            <Block type="controls_if"></Block>
            <Block type="logic_negate"></Block>
            {/* <Block type="logic_compare"></Block> */}
            {/* <Block type="logic_operation"></Block>
                <Block type="logic_boolean"></Block>
                <Block type="logic_null"></Block>
                <Block type="logic_ternary"></Block> */}
        </Category>
        <Category name="Loops" categorystyle="loop_category">
            <Block type="controls_repeat_ext">
                <Value name="TIMES">
                    <Shadow type="math_number">
                        <Field name="NUM">10</Field>
                    </Shadow>
                </Value>
            </Block>
            {/* <Block type="controls_whileUntil"  disabled="true"></Block>
                <Block type="controls_for">
                    <Value name="FROM">
                        <Shadow type="math_number">
                            <Field name="NUM">1</Field>
                        </Shadow>
                    </Value>
                    <Value name="TO">
                        <Shadow type="math_number">
                            <Field name="NUM">10</Field>
                        </Shadow>
                    </Value>
                    <Value name="BY">
                        <Shadow type="math_number">
                            <Field name="NUM">1</Field>
                        </Shadow>
                    </Value>
                </Block>
                <Block type="controls_forEach" disabled="true"></Block>
                <Block type="controls_flow_statements"></Block> */}
        </Category>
        <Sep></Sep>
        {/* <Category name="Variables" categorystyle="variable_category" custom="VARIABLE"></Category> */}
        {/* <Category name="VMT Variables" categorystyle="variable_category" custom="VMT_VARIABLE"></Category> */}
        {/* <Category name="Functions" categorystyle="procedure_category" custom="PROCEDURE"></Category> */}
        {/* </Category> */}
    </>
);
